import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { selectRegistrar, setRegistrarId, registrars, resetApp } from '../../features/app/app-slice';
import { useDispatch, useSelector } from 'react-redux'


const Styled = styled.div`
    /* height: 50px; */
    padding: 10px;
    position: absolute;
    /* top: 10px; */
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    font-size: .9em;
    z-index: 2;
    /* @media screen and (max-width: 500px){
        position: static;
        text-align: center;
        background-color: slategrey;
        display: block;
        width: 100vw;
    } */
`

const StyledSelect = styled.select`
    position: relative;
    appearance: none;
    color: ghostwhite;
    text-decoration: none;
    margin-right: 20px;
    transition: .25s;
    /* white-space: nowrap; */
    &:hover {
        color: white;
        text-decoration: underline;
        background-color: darkslategray;
    }
    border: 0;
    background-color: transparent;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    z-index: 2;
`

const StyledLink = styled(props => <Link {...props} />)`
    color: ghostwhite;
    text-decoration: none;
    margin-right: 20px;
    transition: .5s;
    &:hover {
        color: white;
        text-decoration: underline;
    }
`

const MenuBar = props => {
    const [isLive, setLive] = useState(false);
    const [isResultsPage, setResultsPage] = useState(false);

    useEffect(() => {
        setLive(true);
        setResultsPage(/search/.test(window.location));
    })

    const registrar = useSelector(selectRegistrar);
    const dispatch = useDispatch();

    // const hasWindow = typeof window !== 'undefined';


    return (<Styled>
        <StyledLink 
        to="/"
            onClick={() => dispatch(resetApp())}
        >Get Started</StyledLink>
        {/* <StyledLink to="/how-to-start-a-blog">How to Blog</StyledLink> */}
        <StyledLink to="/premium-domains">Premium Domains</StyledLink>
        <StyledLink to="/resources">Resources</StyledLink>
        {isLive && isResultsPage &&
            <StyledSelect defaultValue={registrar.id}>
                {registrars.map((el, i) => <option
                    key={i}
                    onClick={ev => dispatch(setRegistrarId(i))}
                    value={el.id}>{el.name}</option>
                )}
            </StyledSelect>
        }
    </Styled>)
};

MenuBar.propTypes = {};

export default MenuBar;