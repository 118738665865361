import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Social from '../Social';

const StyledBar = styled.footer`
    margin-top: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    flex-direction: row;
    background-color: ghostwhite;
    min-height: 50px;
    align-items: center;
    @media screen and (max-width: 500px){
        text-align: center;
        display: block;
        min-height: 110px;
    }
`
const FooterLogo = styled.h3`
    color: #555;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 18px;
    font-weight: 400;
    /* line-height: 24px; */
    text-transform: uppercase;
    letter-spacing: 10px;
    text-align: center;
    margin: 0;
`
const A = styled(props => <Link {...props} />)`
    color: darkgrey;
    text-decoration: none;
    transition: .5s;
    &:hover {
        color: black;
        text-decoration: underline;
    }
`

const FooterBar = ({ articleUrl }) => {
    return (<StyledBar>
        <Social articleUrl={articleUrl || 'https://domfeed.com'} />
        <FooterLogo>Domfeed</FooterLogo>
        <div>[ <A to="/">Home</A> | <A to="/privacy-policy">Privacy</A> ]</div>
    </StyledBar>)
};

FooterBar.propTypes = {};

export default FooterBar;