// was middleware to extract domain and tld from valid domain name
module.exports.termExtractor = function termExtractor(params) {
    let { domainTerm, domainTld } = params;
    if (!domainTerm) return params;
    // remove first period
    if (/^\./.test(domainTerm)) return { domainTerm: '', domainTld: params.domainTerm.replace('.', '') };
    // let fixedTerm = filterDomRegEx(domainTerm);
    // let warning;
    // if (fixedTerm.toLowerCase() !== domainTerm.toLowerCase()) warning = 'text was modified';
    // let split = fixedTerm.match(/^([\w|-]+).?(.*)?/);
    let split = domainTerm.match(/^([\w|-]+).?(.*)?/);
    domainTerm = split[1];
    domainTld = domainTld || split[2] || 'com';
    if (
        (typeof domainTerm !== 'string') ||
        (typeof domainTld !== 'string')
    ) throw Error('termExtractor return type(s) invalid.');
    // fixedTld = filterDomRegEx(fixedTld).toLowerCase();

    return ({ ...params, domainTerm, domainTld });
}