import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux';
import { setCompactMode, clickSearchGo, selectSearchTerm } from '../../features/app/app-slice';
import styled from 'styled-components';
// import styled, { keyframes } from 'styled-components';
import { termExtractor } from '../../helpers/domain-parts-extractor';
import { domainReformatter } from '../../helpers/domain-reformatter';
// import { tada } from 'react-animations';



// var keyTimer;
// const doLater = ({ cb, timeout = 1500 }) => {
//     window.clearTimeout(keyTimer);
//     keyTimer = setTimeout(() => {
//         cb();
//     }, timeout);
// }

const StyledInput = styled.input`
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-style: italic;
    font-size: 1.2em;
    height: 2em;
    width: 250px;
    border: solid 1px #808080;
    background: rgba(255,255,255,0.7);
    padding: 2px 10px;
    margin-top: 1.75em;
    transition: 0.25s;
    border-radius: 7px 0 0 7px;
    border-right-width: 0;
    &:focus{
        outline: none;
    }
    &::placeholder {
        color: #888;
    }
    &:focus, &:hover {
        background: white;
        box-shadow: 0 0 6px #fff8;
    }
    @media screen and (max-width: 400px) {
        width: 175px;
    }
`

const StyledSelect = styled.select`
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-style: italic;
    font-size: 1.2em;
    height: 44px;
    border: solid 1px #808080;
    border-left-width: 0;
    background: rgba(255,255,255,0.7);
    padding: 2px 10px;
    margin-top: 1.75em;
    transition: 0.25s;
    appearance: none;
    color: grey;
    margin-top: 1.75em;
    /* margin-left: -70px; */
    &:focus, &:hover {
        color: green;
    }
`

// https://stackoverflow.com/questions/44143582/styled-components-with-animate-css
// const animation = keyframes`${tada}`;

const StyledSubmit = styled.button.attrs({ type: 'submit' })`
    /* display: inline-block; */
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-style: italic;
    font-size: 1.2em;
    height: 44px;
    border: solid 1px #808080;
    background: lightsteelblue;
    color: slategray;
    padding: 2px 10px;
    margin-top: 1.75em;
    transition: 0.25s;
    border-radius: 0 7px 7px 0;
    &:focus, &:hover {
        background: lightgreen;
        color: ghostwhite;
        box-shadow: 0 0 6px #fff8;
    }
`

export const getSetFormData = formData => {
    const currentFormData = JSON.parse(window.sessionStorage.getItem('formData'));
    if (!formData) return currentFormData || {};

    window
        .sessionStorage
        .setItem('formData',
            JSON.stringify({
                ...currentFormData,
                ...formData
            })
        );
}

const BigInput = ({ handleFormChange, formData }) => {
    const [term, setTerm] = useState('');
    const [tld, setTld] = useState('com');
    // const [isLive, setLive] = useState(false);
    // const [animate, setAnimate] = useState(false);

    const dispatch = useDispatch();

    if (formData && formData.domainTerm) {
        getSetFormData(formData);
    }

    const searchTerm = useSelector(selectSearchTerm)

    useEffect(() => {
        // setLive(true);
        setTerm(getSetFormData().domainTerm);
        const localTld = getSetFormData().domainTld;
        if (localTld) setTld(localTld);
        if (searchTerm) setTerm(searchTerm);
    }, [searchTerm]);

    const formAction = changed => {
        // console.log({ changed });
        var formData = { term, tld };
        var domainTerm, domainTld;

        if (changed.extension) {
            ({ domainTerm, domainTld } = termExtractor({ domainTerm: term, domainTld: changed.extension }));
            setTerm(domainTerm || '');
            setTld(changed.extension);
        }

        if (changed.input !== undefined) {
            changed.input = domainReformatter(changed.input);
            changed.input = changed.input.toLowerCase();

            ({ domainTerm, domainTld } = termExtractor({ domainTerm: changed.input }));
            // ({ domainTerm, domainTld, validDomain } = termExtractor({ domainTerm: changed.input, domainTld: tld }));

            // setTerm(changed.input || '');
            setTerm(changed.input || '');
            setTld(domainTld);
        }

        formData = {
            domainTerm: (changed.input !== undefined) ? domainTerm : term,
            domainTld: domainTld || tld || 'com',
            // [] this will be used in FSM to bypass input timer
            // goFlag: changed.goFlag
        }

        getSetFormData(formData);
        // setAnimate(false);
        // if (term) doLater({ cb: () => setAnimate(true) });

        if (typeof handleFormChange === 'function') handleFormChange(formData);
    }

    let tldOptions = ['com', 'io', 'ly', 'net']
    if (tldOptions.indexOf(tld) < 0) tldOptions = tldOptions.concat(tld);

    const cleanTerm = string => string.replace(/[^\w|-]/g, '');

    return (
        <form style={{
            textAlign: 'center',
            whiteSpace: 'nowrap'
        }} >
            <StyledInput
                size="large"
                // defaultValue={term}
                value={term || ''}
                placeholder="success"
                name="domainTerm"
                onChange={ev => formAction({ input: cleanTerm(ev.target.value) })}
                onKeyUp={ev => {
                    if (ev.keyCode === 13) {
                        // formAction({ goFlag: true });
                    }
                }}
            // {...props}
            />
            {/* <InputButton
                src={goIcon}
                onClick={ev => formAction({ goFlag: true })}
            /> */}
            {/* <StyledSelect
                // this must get value from localSession
                value={tld}
                name="domainTld"
                onChange={ev => formAction({ extension: ev.target.value })}
            // onChange={ev => setTld(ev.target.value)}
            >
                {tldOptions.map((el, i) => <option key={i} value={el} >.{el}</option>)}
            </StyledSelect> */}
            <StyledSubmit
                // isAnimated={animate}
                onClick={ev => {
                    // [] preventDefault should only be called if page is live
                    // if (isLive) ev.preventDefault();
                    ev.preventDefault();
                    dispatch(setCompactMode(true));
                    dispatch(clickSearchGo({ searchTerm: term }));
                    // formAction({ goFlag: true });
                }}
            >
                Go!
            </StyledSubmit>
        </form>
    )
};

// BigInput.propTypes = {};

export default BigInput;