import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import fbIcon from './icons/iconfinder_facebook_circle_294710.svg';
import pinterestIcon from './icons/iconfinder_pinterest_circle_294708.svg';
import googleIcon from './icons/iconfinder_google_circle_294707.svg';
import twitterIcon from './icons/iconfinder_twitter_circle_294709.svg';
import liIcon from './icons/iconfinder_linkedin_circle_294706.svg';


const CircleIcon = styled.img`
    width: 18px;
    margin: 0 5px;
    transition: .25s;
    filter: grayscale(1) opacity(.5);
    transform: scale(1);
    &:hover {
        filter: grayscale(0) opacity(1);
        transform: scale(1.5);
        cursor: pointer;
    }
`
const CirclesBar = styled.div`
    margin-top: 10px;
`

const SocialButtonStyle = styled.div`
    display: inline-flex;
    box-sizing: border-box;
    align-items: center;
    flex-direction: row;
    border-radius: 7px;
    margin: 0 10px;
    background-color: ${({ bgColor }) => bgColor};
    padding: 5px 10px;
    color: whitesmoke;
    font-weight: 500;
    transition: .25s;
    border: 1px solid transparent;
    & img {
        height: 32px;
        margin-right: 5px;
    }
    &:hover {
        color: black;
        background-color: white;
        border: 1px solid grey;
    }
`
const ButtonsBar = styled.div`
    margin: 10px;
    display: inline-flex;
    align-items: center;
    .label {
        text-transform: uppercase;
        font-size: 1.2em;
        font-weight: 400;
    }
`


const socialObjBuilder = ({ articleUrl, socialName }) => {
    if (!articleUrl || !/^http/i.test(articleUrl)) throw Error('Invalid URL supplied to social object builder.')
    const socialObj = [
        { displayName: 'Facebook', href: `https://www.facebook.com/sharer/sharer.php?u=${articleUrl}`, icon: fbIcon, bgColor: '#4E71A8' },
        { displayName: 'Pinterest', href: `https://pinterest.com/pin/create/button/?url=&media=${articleUrl}&description`, icon: pinterestIcon, bgColor: '#CB2027' },
        { displayName: 'Twitter', href: `https://twitter.com/home?status=${articleUrl}`, icon: twitterIcon, bgColor: '#1CB7EB' },
        { displayName: 'Google+', href: `https://plus.google.com/share?url=${articleUrl}`, icon: googleIcon, bgColor: '#DC4E41' },
        { displayName: 'LinkedIn', href: `https://${articleUrl}`, icon: liIcon, bgColor: '#007AB9' }
    ];
    if (!socialName) return socialObj;
    return socialObj.find(network => (network.displayName === socialName));
}

const miniCirclesBuilder = ({ href, icon }) => (
    <a href={href} target="_new" key={href}>
        <CircleIcon src={icon} />
    </a>
)

const buttonBuilder = ({ href, icon, displayName, bgColor }) => (
    <a href={href} target="_new" key={href}>
        <SocialButtonStyle bgColor={bgColor}>
            <img src={icon} />
            {displayName}
        </SocialButtonStyle>
    </a>
)

const SocialCircles = ({ articleUrl }) => (<CirclesBar>{
    ['Facebook', 'Pinterest', 'Twitter']
        .map(network =>
            miniCirclesBuilder(socialObjBuilder({ articleUrl, socialName: network }))
        )
}</CirclesBar>)

const SocialTangles = ({ articleUrl }) => (<ButtonsBar>
    <span className='label'>Share this:</span>
    {
        ['Facebook', 'Pinterest', 'Twitter']
            .map(network =>
                buttonBuilder(socialObjBuilder({ articleUrl, socialName: network }))
            )
    }</ButtonsBar>)

const Social = (props) => ((props.widgetType !== 'large') ? <SocialCircles {...props} /> : <SocialTangles {...props} />)

Social.propTypes = {
    widgetType: PropTypes.oneOf(['large', null])
};

export default Social;