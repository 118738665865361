import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.header`
    /* position: absolute; */
    /* margin: 0;
    top: 0;
    left: 0;
    width: 100%; */
    /* min-height: ${({ height }) => (height || '325px')}; */
    background-image: url(${({ image }) => image});
    /* background-attachment: fixed; */
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: top;
    background-size: cover;
    filter: contrast(.7) brightness(140%);
    /* z-index: -2; */
`
const ColorOverlay = styled.div`
    z-index: -1;
    margin: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(149deg, rgba(2,0,36,1) 0%, rgba(9,121,15,1) 0%, rgba(0,129,255,1) 100%);
    filter: opacity(.7);
`
const Content = styled.div`
    box-sizing: border-box;
    padding: 60px 0 25px 0;
    /* @media screen and (max-width: 500px){
        padding: 0 0 25px 0;
    } */
    position: relative;
    width: 100%;
    height: 100%;
`

const Hero = props => {
    const { bgImage, preLoadImage, children, height } = props;
    const [isLive, setLive] = useState(false);
    useEffect(() => {
        setLive(true);
    }, []);
    return (
        <Container
            image={preLoadImage ? (isLive ? bgImage : preLoadImage) : bgImage}
            height={height} >
            <ColorOverlay />
            <Content>
                {children}
            </Content>
        </Container>
    )
}

Hero.propTypes = {
    bgImage: PropTypes.string,
    preLoadImage: PropTypes.string
}

export default Hero;