import React, { useState, useEffect,  } from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import Hero from '../components/Hero';
import { Helmet } from 'react-helmet'
import HeroText from '../components/HeroText';
import MenuBar from '../components/GatsbyMenuBar';
import BigInput from '../components/BigInput';
import FooterBar from '../components/FooterBar';
import imgBalloonLg from '../images/balloons.jpg'
import imgBalloonTh from '../images/balloons_th.jpg'
// import PreloadFsm from './PreloadFsm';
import ScrollTo from '../components/ScrollTo';
import '../style.css';
import '../pace.css';
import loadScript from '../helpers/load-script';
import styled from 'styled-components'

const Page = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`

const Layout = ({
    children,
    formData,
    overrideContent,
    overrideFormChange
}) => {
    const [articleUrl, setArticleUrl] = useState();
    useEffect(() => {
        setArticleUrl(window.location);
        // [] how to pass a variable into the loaded script?
        // loadScript('/js/matomo.js');
        loadScript('/js/pace.min.js');
    }, [])

    // var keyTimer = null;
    // waits a time for no keypress before navigating to search page
    // does not interact with FSM here

    // XX do not remove! [] commented out until can deal with waiting for TLD selection
    const handleFormChange = formData => {
        // [] make so can be called by moving mouse and scrolling without formData
        // set two values - domainTerm and domainTld

        if (formData.goFlag) navigate('/search');

        // window.clearTimeout(keyTimer);
        // keyTimer = setTimeout(() => {
        //     if ((window.location.pathname !== '/search') && formData.domainTerm) navigate('/search');
        // }, 2000);
    }

    return (<Page>
        {/* <PreloadFsm /> */}
        <ScrollTo />
        <Helmet>
            <script async defer data-website-id="db5beb4f-bd4a-4772-8687-bdd5585ac7df" src="https://umami.app.computercarl.com/umami.js"></script>
        </Helmet>
        <Hero
            // height={overrideContent ? '200px' : null}
            bgImage={imgBalloonLg}
            preLoagImage={imgBalloonTh}
        >
            <HeroText>
                <h3>Domfeed</h3>
                {overrideContent ||
                    <>
                        <h1>Your Perfect Domain Name</h1>
                        <h2>is only keystrokes away!</h2>
                        <BigInput
                            placeholder="success"
                            formData={formData}
                            handleFormChange={overrideFormChange || handleFormChange}
                        />
                    </>
                }
            </HeroText>
        </Hero>
        <MenuBar />
        {children}
        <FooterBar articleUrl={articleUrl} />
    </Page>)
};

Layout.propTypes = {
    overrideFormChange: PropTypes.func,
    overrideContent: PropTypes.element
};

export default Layout;