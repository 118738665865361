import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

const Styled = styled.div`
  h1 {
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 2.75em;
    font-weight: 700;
    line-height: 1em;
    text-align: center;
    margin: 5px;
  }
  h2 {
    margin: 0;
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.75em;
    font-weight: 200;
    /* line-height: 34px; */
    text-align: center;
  }
  h3 {
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-transform: uppercase;
    letter-spacing: 18px;
    text-align: center;
  }
`

const HeroText = ({ children }) => {
  return (<Styled> {children} </Styled>)
};

// HeroText.propTypes = {};

export default HeroText;